import React, {useState} from "react";
import { PerProject } from "./PerProject";
import Livingroom from './Assets/livingroom.jpg';
import Balcony from './Assets/balcony.jpg';
import NC from './Assets/nc.png';
import NCLP from './Assets/NClanding.png';
import V89 from './Assets/v89.png';
import VLP from './Assets/vaultVault.png';
import Chars from './Assets/chars.png';
import Bottle from './Assets/bottle.jpg';
import Beach from './Assets/beach.jpg';
import './Newprojects.css';
import Car from './Assets/car.png';
import Feather from './Assets/feather.png';
import CarVideo from './Assets/caar.mp4';
import FeatherAnim from './Assets/feathersall.mp4';


export const NewProjects =() =>{

    const [fullImage, setFullImage] = useState(null);

  

    const data =[
        {
            hoverimg:VLP,
            title:"Vault 89",
            desc:"Vibrant landing page animation made on Blender and Unreal Engine.",
            pagelink:"https://vault89.com/",
            category: 'Web',
            mainim: { type: 'image', src: V89 },
            },

        {
            hoverimg:NCLP,
            title:"Native Coders",
            desc:"WordPress website incorporating Bledner animations and AfterEffects animation.",
            link: "https://www.nativecoders.se/",
            category: 'Web',
            mainim: { type: 'image', src: NC },
            },
        {
            hoverimg:Bottle,
            title:"Product Mockup",
            desc:"3D product mockup done on Blender 3D",
            category: 'Product Design',
            mainim: { type: 'image', src: Bottle },
            },

            {
            hoverimg:Beach,
            title:"Beach Wallpaper",
            desc:"Photorealistic wallposter on Blender 3D ",
            category: '3D',
            mainim: { type: 'image', src: Beach },
            },
        {
        hoverimg:Livingroom,
        title:"Living Room Render",
        desc:"3D architectural  visualization done on unreal engine and Blender",
        category: '3D',
        mainim: { type: 'image', src: Livingroom },
        },

        {
        hoverimg:Balcony,
        title:"Balcony render",
        desc:"3D architectural  visualization done on unreal engine and Blender",
        category: '3D',
        mainim: { type: 'image', src: Balcony },
        },


        {
        hoverimg:Chars,
        title:"Balcony",
        desc:"This project delves into the exciting world of MetaHumans within Unreal Engine..",
        category:'3D',
        mainim: { type: 'image', src: Chars },
        },

        {
        hoverimg:Car,
        title:"Balcony",
        desc:"This project delves into the exciting world of MetaHumans within Unreal Engine..",
        category:'3D',
        mainim: { type: 'video', src: CarVideo },
        },

        {
        hoverimg:Feather,
        title:"Balcony",
        desc:"This project delves into the exciting world of MetaHumans within Unreal Engine..",
        category:'3D',
        mainim: { type: 'video', src: FeatherAnim },
        },



        
        

    ]

    const handleShowImage = (mainim) => {
        setFullImage(mainim);
      };

    const [currentIndex, setCurrentIndex] = useState(0);

    

    const currentData = data[currentIndex];

    return(
        <div className='section-3'>
            <div className="gallery-title">
                <p className="gal">SELECTED </p>
                <span> gallery</span>

                </div>
                <p className="gal">PROJECTS</p>
                <p className= "text-bit">MY APPROACH IS CRAFTING FUNCTIONAL USER-CENTRIC PRODUCTS TO MEET AND EXCEED BUSINESS GOALS</p>
           
            <div className='allprojects'>
            {data.map((project, index) => (
                <PerProject
                key={index} // Using index as key, but a unique identifier is preferred if available
                hoverimg={project.hoverimg}
                title={project.title}
                desc={project.desc}
                category={project.category}
                mainim={project.mainim}
                onShowImage={handleShowImage}
                />
            ))}
                </div>

                {fullImage && (
                <div className="overlay" onClick={() => setFullImage(null)}>
                {fullImage.type === 'image' ? (
                  <img src={fullImage.src} alt="Full view" className="full-image" />
                ) : (
                  <video src={fullImage.src} autoPlay className="full-image" />
                )}
              </div>
            )}
        
        </div>
    )
}