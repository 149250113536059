
import './App.css';
import { LandingPage } from './LandingPage';

import { Services } from './Services';

import { NewProjects } from './Newprojects';


function App() {
  return (
    <div className="App">
     
      <LandingPage />
    
      <NewProjects />
       
     
     
    </div>
  );
}

export default App;
