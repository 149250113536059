import React from 'react';

import Spline from '@splinetool/react-spline';


export default function BgModel () {
  

  return( 
  <Spline scene="https://prod.spline.design/bJPtf21UMnvadoLS/scene.splinecode" style={{ minHeight: '100vh', minWidth: '100vw' }}/>
);
}














