import React from "react";
import "./LandingPage.css";
import BgModel from "./Home";

export const LandingPage = () => {

    const handleDownload = () => {
      
        const link = document.createElement('a');
        link.href = '/TracyWankio_3DFrontend.pdf'; // Path to your PDF file in the public folder
        link.download = 'TracyWankio_3DFrontend.pdf'; // Name of the file to be downloaded
        window.open('/TracyWankio_3DFrontend.pdf','_blank')
        link.click();
    
    };
    return (
        <div className="homepage">
            <BgModel />
         
           
            <div className="overlap-group-wrapper">
                <div className="overlap-group">
                    <div className="text-wrapper">TRACY WANKIO</div>
                    
                    <div className="landing-page-title">

                       
                        <p className="subtitle">Hey ! I am a <strong>Software Developer</strong></p>
                        <p className="name"> I enjoy crafting visually stunning and interactive digital experiences through front-end development, 3D design, and UI/UX design.</p>
                        <div className='button-div'>
                        <button className="button-56" role="button" onClick={handleDownload}>My Resume</button>
                        </div>
                    </div>
                 
                </div>
            </div>

           
            
  
        </div>
    );
};
