import React ,  { useState } from "react";
import "./PerProject.css"; 





export const PerProject  = ({ hoverimg, title, desc, category, mainim, onShowImage })  => {
    return(
        <div className="project-container">
            <div className='banner'>
           
           <img src={hoverimg} alt={title} className="project-image"  /> 
           <button className="project-category" >{category}</button>

           </div>
            <div className='desc'>
                <div className='desc-cont'>
                <p className='project-title'>{title}</p>
                <p>{desc}</p>
                </div>
                <button className="add-button" onClick={() => onShowImage(mainim)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </button>
            </div>

        </div>
    )
};